


import React, { useState, useEffect } from 'react';
import {read, utils, writeFileXLSX, write, JSON2SheetOpts } from 'xlsx';
import { GiGrapes } from 'react-icons/gi'; 
import { MdClose } from 'react-icons/md';
import { RiFileAddLine, RiFile3Fill, RiFileExcel2Line } from 'react-icons/ri';

import './styles/table2.css';

const ExcelReader = () => {
  const [json, setJson] = useState([]);
  const [json2, setJson2] = useState([]);

  const [brands, setBrands] = useState({});
  const [empty, setEmpty] = useState([]);

  const [total, setTotal] = useState(0);

  const [summaryOpen, setSummaryOpen] = useState(false);

  useEffect(() => {
    const handleFileDrop = (event) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = read(data, {type: 'array'});
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = utils.sheet_to_json(worksheet);
        let cleanedData = await cleanData(jsonData);
        assignCleanData(cleanedData, file.name);
      };
      reader.readAsArrayBuffer(file);
    };

    document.addEventListener('dragenter', (event) => {
      event.preventDefault();
    });
    document.addEventListener('dragover', (event) => {
      event.preventDefault();
    });
    document.addEventListener('drop', handleFileDrop);

    return () => {
      document.removeEventListener('dragenter', (event) => {
        event.preventDefault();
      });
      document.removeEventListener('dragover', (event) => {
        event.preventDefault();
      });
      document.removeEventListener('drop', handleFileDrop);
    };
  }, []);

  useEffect(() => {
    debugger;
    let sumData = {};
    if (json.length){
      for (var i = 0; i < json.length; i++){
        let item = json[i];
        if (sumData[`${item.MARCA}`]){
          let shops = {...sumData[`${item.MARCA}`]}
          let tienda = item["TIENDA"];
          //let indexOfGrupo = item["TIENDA"].indexOf("GrupoNumero1-");
          //if (indexOfGrupo !== -1){
          //  tienda = item["TIENDA"].substring(13, 19);
          //}
          if (shops[`${tienda}`]){
            let itemPrice = getPriceForOrder(item["NUMERO_PEDIDO"], true);
            let data = [... shops[`${tienda}`]];
            data.push({
              ...item,
              price: itemPrice
            })

            Object.assign(shops, {[`${tienda}`]: data})
          }else{
            Object.assign(shops, {[`${tienda}`]: []});
            i = i - 1;
          }
          Object.assign(sumData, {[`${item.MARCA}`]: shops})
        }else{
          Object.assign(sumData, {[`${item.MARCA}`]: {}})
          i = i -1;
        }
      }
      console.log({sumData});
      setBrands(sumData);
    }
  },[json])

  useEffect(() => {
    if (Object.keys(brands).length > 0 && json.length > 0 && json2.length > 0){
      setSummaryOpen(true);
    }
  },[brands, json, json2])

  const assignCleanData = (data, filename) => {
    if (filename.indexOf("excel_pedido") !== -1){
      setJson(data);
    }
    if (filename.indexOf("cex") !== -1 || filename.indexOf("CEX") !== -1 || filename.indexOf("factu") !== -1 || filename.indexOf("FACTU") !== -1 || filename.indexOf("correos") !== -1 || filename.indexOf("express") !== -1){
      setJson2(data);
    }
  }

  const cleanData = (data) => {
    const keysToRemove = [
        "Alerta",
        "Categoría",
        "Contador asignado",
        "Estado del SLA",
        "Está escalada",
        "Gestor del proceso",
        "Hora de solicitud",
        "ID principal",
        "Máximo nivel de soporte",
        "Nivel de escalamiento",
        "Nivel de soporte actual",
        "Reabrir contador",
        "Tiempo de Resolucion",
        "Tiempo de Respuesta",
    ];
    const keysToChange = [
        {from : "#", to: "code"},
        {from : "PEDIDO - Operador logístico", to: "OP. LOGÍSTICO"},
        {from : "TIENDA_DEL_VENDEDOR", to: "TIENDA"},
    ]
    data.forEach(el => {
        keysToRemove.forEach(key => {
            if (el.hasOwnProperty(key)){
                delete el[key];
            }
        })
        keysToChange.forEach(key2 => {
            if (el.hasOwnProperty(key2.from)){
                Object.assign(el, {[key2.to]: el[key2.from]});
                delete el[key2.from];
            }
        })
    });
    return data;
  }

  const getPriceForOrder = (orderRef, adding = false) => {
    let priceFound = 0;
    for (var i = 0; i < json2.length; i++){
      let item = json2[i];
      if (item["REFERENCIA"]){
        if (item["REFERENCIA"].indexOf(orderRef) !== -1){
          priceFound = parseFloat(item["IMP. TOTAL"].replace(",", "."));
        }
      }
    }

    if(adding){
        return priceFound;
    }else{
      if (priceFound === 0){
        return <span style={{color: 'red'}}>{"NOT FOUND"}</span>
      }
      return <span style={{color: 'green'}}>{priceFound}</span>
    }
  }

  const formatData = (key, value) => {
    let formatedValue = value;
    let cellStyle={fontFamily: "monospace", fontSize: 16};

    switch(key){
        /*case "TIENDA":
          let indexOfGrupo = value.indexOf("GrupoNumero1-");
          if (indexOfGrupo !== -1) {
            formatedValue = value.substring(13, 19);
          }
          break; */
        case "Type":
            formatedValue = value[0];
            break;
        default:
            formatedValue = value;
            break;
    }
    return <div style={cellStyle} className={`cell ${key}`} key={`${key + "-" + value}`}>{formatedValue}</div>
  }

  const hasNoValidShippmentInvoice = (data) => {
    let price = getPriceForOrder(data["NUMERO_PEDIDO"], true);
    if (price === 0){
      return true;
    }else{
      return false;
    }
  }

  const handleExportToExcel = () => {
    let tiendaPrecio = [["Tienda", "Precio"]]; //{tienda: "LEV01", precio: 25.45}
    let allTotal = 0;

    Object.keys(brands).map(brand => {
      let shops = brands[brand];
      let brandTotal = 0;

      tiendaPrecio.push([brand,``]);

      Object.keys(shops).map(shop => {
        let shopTotal = shops[shop].reduce((a,b) => {
          let suma = a.price+b.price
          return {price: suma};
        }, {price: 0});
        brandTotal = brandTotal + shopTotal.price;
        allTotal = allTotal + shopTotal.price;

        if (shopTotal.price > 0){
          tiendaPrecio.push([`${shop}`,`${shopTotal.price.toFixed(2)}`]);
        }


      });
      tiendaPrecio.push(["Total",`${brandTotal.toFixed(2)}`]);
      tiendaPrecio.push(["",""])
    })
    tiendaPrecio.push(["",""])
    tiendaPrecio.push(["TOTAL",`${allTotal.toFixed(2)}`])

    let excessJson = json.filter((data) => {
      if (data["OP. LOGÍSTICO"] === "Seur SDS"){
        return false;
      }else{
        if (hasNoValidShippmentInvoice(data)){
          return true;
        }else{
          return false;
        }
      }
    });

    console.log({json2})
    let excessCEX = []
    json2.map((dataItem) => {
      let ref = dataItem["REFERENCIA"].replaceAll(' ', '');
      let found = false;
      for (var i = 0; i < json.length; i++){
        if (json[i]['NUMERO_PEDIDO'].replaceAll(" ", "") === ref){
          found = true;
        }
      }
      if (!found){
        excessCEX.push(dataItem);
      }
    })

      // Create a new workbook
      const workbook = utils.book_new();

      // Create sheet 1 and add data
      const sheet1 = utils.aoa_to_sheet(tiendaPrecio);
      utils.book_append_sheet(workbook, sheet1, 'Reparto de costes');

      // Create sheet 2 and add data
      const sheet2 = utils.json_to_sheet(excessJson);
      utils.book_append_sheet(workbook, sheet2, 'Sin asociar Hermes');

      // Create sheet 3 and add data
      const sheet3 = utils.json_to_sheet(excessCEX);
      utils.book_append_sheet(workbook, sheet3, 'Sin asociar CEX');

      // Generate the XLSX file
      const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob object from the XLSX data
      const blob = new Blob([wbout], { type: 'application/octet-stream' });

      // Generate a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'FacturaciónCEX_contrastado.xlsx';
    
      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();
    
      // Clean up the temporary link
      document.body.removeChild(link);

  }

  const drawData = () => {
    return json.map((row, index) => {

        let rowStyle={fontFamily: "monospace", fontSize: 16};

        if (row['OP. LOGÍSTICO'] && row['OP. LOGÍSTICO'] === "Seur SDS"){
          return;
        }

          return (
              <div 
                className={`row`} 
                key={index} 
                style={rowStyle}
              >
                  {Object.keys(row).map((key, index) => 
                      formatData(key, row[key])
                  )}
                  <div className='cell price'>{getPriceForOrder(row['NUMERO_PEDIDO'])}</div>
              </div>
          )
    });
  }

  const drawSummary = () => {
    let allTotal = 0;

    return (<div>
    {
      Object.keys(brands).map(brand => {
        let shops = brands[brand];
        let brandTotal = 0;
        return (
          <div className='summaryBrand'>
            <div className='summaryBrandTitle'>
              {brand}
            </div>
            {Object.keys(shops).map(shop => {
              let shopTotal = shops[shop].reduce((a,b) => {
                let suma = a.price+b.price
                return {price: suma};
              }, {price: 0});
              brandTotal = brandTotal + shopTotal.price;
              allTotal = allTotal + shopTotal.price
              return (
                <div className={`summaryBrandShop ${shopTotal.price < 0.1 ? 'zeroSales' : 'sales'}`}>
                  <div className='summaryBrandShopName'>
                    {shop}
                  </div>
                  <div className='summaryBrandShopTotal'>
                    {shopTotal.price.toFixed(2)}€
                  </div>
                </div>
              )
            })}
            <div className='summaryBrandTotal'>Total: {brandTotal.toFixed(2)}€</div>
          </div>
        )
      })
    }  
      <div>
        Total: {allTotal.toFixed(2)}€
      </div>
    </div>)
  }

  const FileItem = ({file}) => {
    let checked = false;
    if (file === "Pedidos Hermes"){
      if (json.length > 0){
        checked = true;
      }
    }
    if (file === "Facturación Correos"){
      if (json2.length > 0){
        checked = true;
      }
    }

    return (
      <div className='fileItem'>
        <div className='fileIcon'>
          {checked ? 
            <RiFile3Fill color="green"/>
          :
            <RiFileAddLine color="red"/>
          }
        </div>
        <div className={`fileName ${checked ? 'checked' : ''}`}>
          {file}
        </div>
      </div>
    )
  }

  return (
    <>
    <div id="summaryDiv" className={`${summaryOpen ? 'opened' : ''}`}>
      <div className='toggleSummaryDiv' onClick={() => setSummaryOpen(true)}>
        <GiGrapes size={24}/>
      </div>
      <div>
        <div className='closeBtnDiv' onClick={() => setSummaryOpen(false)}>
          <MdClose size={40} color='#fffff' />
        </div>
        <div className='summaryContent'>
          {drawSummary()}
          <div className='summaryBottomSpacer'></div>
        </div>
      </div>
    </div>
    <div className="filesChecklist">
      <FileItem file="Pedidos Hermes" />
      <FileItem file="Facturación Correos" />
    </div>
    {json.length !== 0 && json2.length !== 0 ?
    <div className='exportBtnDiv'>
      <div className='exportBtn' onClick={handleExportToExcel}>
        <RiFileExcel2Line size={30}/>
      </div>
    </div>
    : null}
    <div className={`dataList ${summaryOpen ? 'static' : ''}`} onClick={() => {}}>
      {json.length > 0 ? (
        <div className={`table ${summaryOpen ? 'static': 'static'}`}>
          <div className="headers">
            <div className="row">
              {Object.keys(json[0]).map((key) => (
                <div className={`cell header ${key}`} key={key}>{key}</div>
                ))}
              <div className='cell header price' key="Precio">Precio</div>
            </div>
          </div>
          <div className="body">
            {drawData()}
          </div>
        </div>
      )
      :
      <div>
        <h1>Facturacion C.EX.</h1>
        <p>Soltar dos archivos, el archivo de facturación de correos express y el archivo de pedidos de hermes.</p>
      </div>
    }
    </div>
    </>
  );
};

export default ExcelReader;
