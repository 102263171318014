import React, {useState} from 'react'
import { SketchPicker } from 'react-color'

function NewGroupForm({...props}) {

  const [groupname, setGroupname] = useState("");
  const [color, setColor] = useState("#E85555");

  const validData = () => {
    if (groupname !== ""){
      return true;
    }
    return false;
  }

  const createGroup = () => {
    debugger;
    let idValid = validData();
    if (idValid){
      props.createGroup(groupname, color);
    }else{
      alert("Details missing.")
    }
  }


  let isValid = validData();
  return (
    <div className={`newGroupForm`}>
      <div className='closeButtonDiv'>
        <div onClick={props.closeBtn} className='closeButton'>X</div>
      </div>
      <h5>New Group</h5>
      <input className='newGroupNameInput' placeholder='Name' value={groupname} onChange={e => setGroupname(e.target.value)} />
      <div className='newGroupColorDiv'>
        <SketchPicker
          color={color}
          onChange={e => setColor(e.hex)}
        />
      </div>
      <div className='newGroupCreateButtonDiv'>
        <div onClick={createGroup} className={`newGroupCreateButton ${isValid ? "valid" : ""}`}>Create</div>
      </div>
    </div>
  )
}

export default NewGroupForm