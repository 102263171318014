import React, { useEffect, useState } from 'react'
import './styles/themes.css';

function ThemeShowroom() {
  const [htmls, setHtmls] = useState([]);
  const [template, setTemplate] = useState("");
  const [themes, setThemes] = useState([]);

  const [configString, setConfigString] = useState("");
  const [loaded, setLoaded] = useState(false);

  const [page, setPage] = useState(0);
  const [theme, setTheme] = useState(0);

  useEffect(() => {
    if (configString !== ""){
      try{
        let parsedConfig = JSON.parse(configString);
        setHtmls(parsedConfig.HTMLs);
        setTemplate(parsedConfig.template);
        setThemes(parsedConfig.themes);
        setLoaded(true);
        setPage(1);
        setTheme(1);

      }catch(err){
        alert("Error en el formato del JSON");
      }
    }
  },[configString])

  // useEffect(() => {
  //   if (theme !== -1){
  //     if (themes[theme-1].styles.length){
  //       injectStyles(themes[theme-1]);
  //     }
  //   }
  // }, [theme]);

  const drawThemesView = () => {
    return (
      <div className='themes-main'>
        {/* <div className='themes-pages'>{drawPages()}</div> */}
        <div className='themes-themes'>{drawThemes()}</div>
        <div className='themes-htmls'>{drawHTMLs()}</div>
      </div>
    )
  }

  // const drawPages = () => {
  //   return (
  //     <div>
  //       <div></div>
  //       <div>
  //         <h3>Pages</h3>
  //       </div>
  //       {htmls.map((el, i) => {
  //         return (
  //           <div key={i} className={`page ${page-1 === i ? "active" : ""}`} onClick={() => setPage(i+1)}>
  //             <h5>{el.title}</h5>
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  // const drawThemes = () => {
  //   return (
  //     <div>
  //       <div></div>
  //       <div>
  //         <h3>Themes</h3>
  //       </div>
  //       {themes.map((el, i) => {
  //         return (
  //           <div key={i} className={`theme ${theme-1 === i ? "active" : ""}`} onClick={() => setTheme(i+1)}>
  //             <h5>{el.title}</h5>
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  const drawThemes = () => {
    if (htmls){
      return (
        <div>
          <div></div>
          <div>
            <h3>Themes</h3>
          </div>
          {htmls.map((el, i) => {
            return (
              <div key={i} className={`theme ${page-1 === i ? "active" : ""}`} onClick={() => setPage(i+1)}>
                <h5>{el.title}</h5>
              </div>
            )
          })}
        </div>
      )
    }else{
      return null;
    }
  }

  const drawHTMLs = () => {
    if (page && htmls){
      return (
        <div className='htmls'>
          <div className='iphoneView' dangerouslySetInnerHTML={{__html: htmls[page-1].code}}></div>
        </div>
      )
    }else{
      return null;
    }
  }

  // const injectStyles = (stylesObj) => {
  //   let styles = "";
  
  //   debugger;
  //   stylesObj.styles.forEach(item => {
  //     let styleForClass = `.${item.class} {`;
  //     item.properties.forEach(prop => {
  //       styleForClass += `${prop.name}: ${prop.value};`;
  //     });
  //     styleForClass += '}';
  //     styles += styleForClass;
  //   });
  
  //   const styleElement = document.createElement('style');
  //   // styleElement.type = 'text/css';
  //   styleElement.innerHTML = styles; //appendChild(document.createTextNode(styles));
  //   document.head.appendChild(styleElement);
  // }

  if (loaded){
    return drawThemesView();
  }

  return (
    <div>
      <textarea placeholder='Paste JSON here' value={configString} onChange={e => setConfigString(e.target.value)}></textarea>
    </div>
  )
}

export default ThemeShowroom