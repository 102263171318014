
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {read, utils} from 'xlsx';
import NewGroupForm from './NewGroupForm';

import './styles/table.css';
import './styles/SysAidApi.css';

const SysAidApiIntegration = ({...props}) => {
  const [json, setJson] = useState([]);
  const [json2, setJson2] = useState([]);
  const [contectMenuItem, setContextMenuItem] = useState(null);
  const [contectMenuItemIsInGroup, setContextMenuItemIsInGroup] = useState(false);
  const [contectMenuGroupsOpen, setContextMenuGroupsOpen] = useState(false);
  const [newGroupFormOpen, setNewGroupFormOpen] = useState(false);
  const [markedItems, setMarkedItems] = useState({});
  const [itemComments, setItemComments] = useState({});
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [filter, setFilter] = useState("");
  const [groups, setGroups] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [loggedInToSysAid, setLoggedInToSysAid] = useState(false);
  const [sysaidLoginModalOpen, setSysaidLoginModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState(-1);

  useEffect(() => {
    if (loggedInToSysAid){
      getSRSysAid();
    }
  }, [loggedInToSysAid])

  useEffect(() => {
    // const handleFileDrop = (event) => {
    //   event.preventDefault();
    //   const file = event.dataTransfer.files[0];
    //   const reader = new FileReader();
    //   reader.onload = async (event) => {
    //     const data = new Uint8Array(event.target.result);
    //     const workbook = read(data, {type: 'array'});
    //     const firstSheetName = workbook.SheetNames[0];
    //     const worksheet = workbook.Sheets[firstSheetName];
    //     const jsonData = utils.sheet_to_json(worksheet);
    //     let cleanedData = await cleanData(jsonData);
    //     setJson(cleanedData);
    //     setShouldUpdate(true);
    //   };
    //   reader.readAsArrayBuffer(file);
    // };

    // document.addEventListener('keydown', handleKeyDowns, false);

    // debugger;
    // axios({
    //   method: "POST",
    //   url: "https://gn1.tvcologan.com/api/getData.php",
    //   data: {
    //     key: props.passphrase
    //   },
    //   headers : {
    //     'Content-Type' : 'text/plain'
    //   }
    // }).then(resp => {
    //   let data = JSON.parse(resp.data.data);

    //   let markedItemFLS = data.markedItems || JSON.parse(localStorage.getItem('ticket-marked-ids'));
    //   let itemCommentsFLS = data.itemComments || JSON.parse(localStorage.getItem('ticket-id-comments'));
    //   let itemGroups = data.itemGroups || [];
    //   let jsonData = data.tickets || [];

    //   if (markedItemFLS){
    //     setMarkedItems(markedItemFLS);
    //   }
    //   if (itemCommentsFLS){
    //     setItemComments(itemCommentsFLS);
    //   }
    //   if (itemGroups){
    //     setGroups(itemGroups);
    //   }
    //   if (jsonData){
    //     setJson(jsonData);
    //   }
    // });

    document.addEventListener('dragenter', (event) => {
      event.preventDefault();
    });
    document.addEventListener('dragover', (event) => {
      event.preventDefault();
    });
    // document.addEventListener('drop', handleFileDrop);

    return () => {
      document.removeEventListener('dragenter', (event) => {
        event.preventDefault();
      });
      document.removeEventListener('dragover', (event) => {
        event.preventDefault();
      });
      // document.removeEventListener('drop', handleFileDrop);
    };
  }, []);

  const loginSysAid = () => {

    axios({
      method: "POST",
      url: "https://gruponumero1.sysaidit.com/api/v1/login",
      data: {
        user_name: username,
        password: password,
        mobile_app: true
      },
      headers: {}
    }).then(resp => {
      console.log({resp});
      setLoggedInToSysAid(true);
      setUserId(resp.data.user.id);
    })

  }

  const getSRSysAid = () => {
    axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://gruponumero1.sysaidit.com/api/v1/sr?limit=100&fields=responsibility,title,submit_user,alertID,description,escalation,problem_type,problem_sub_type,third_level_category,urgency,assigned_group,department,insert_time,update_time,status&archive=0&responsibility=${userId}`,
      headers: {}
    }).then(resp => {
      console.log({resp});
      setJson2(resp);
    })
  }

  const logout = () => {

  }

  // useEffect(() => {
  //   if (shouldUpdate){
  //     saveChagesToCloud();
  //     setShouldUpdate(false);
  //   }
  // },[shouldUpdate])

  // const saveChagesToCloud = () => {
  //   debugger;
  //   setTimeout(() => {
  //     axios({
  //       method: "POST",
  //       url: "https://gn1.tvcologan.com/api/index.php",
  //       data: {
  //         key: props.passphrase,
  //         data:{
  //           markedItems: markedItems,
  //           itemComments: itemComments,
  //           itemGroups: groups,
  //           tickets: json
  //         }
  //       },
  //       headers : {
  //         'Content-Type' : 'text/plain'
  //       }
  //     }).then(resp => console.log("Response from server POST:",{resp}));
  //   },0)
  // }

  // const handleKeyDowns = (e) => {
  //   if (e.code === 'Space' && e.ctrlKey){
  //     setOpenSearchBar(true);
  //     document.getElementById("seachBarInput").focus();
  //   }
  //   if (openSearchBar && e.code === "Escape"){
  //     closeSearchBar();
  //   }
  // }

  // const closeSearchBar = () => {
  //   setOpenSearchBar(false);
  // }

  const handleCopy = async (item) => {
    hideContextMenu();
    let text = item['code'];
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleDoubleClick = (item) => {
    hideContextMenu();
    let url = `https://soporte.gruponumero1.com/SREdit.jsp?QuickAcess&id=${item['code']}`;
    window.open(url, '_blank').focus();
  }

  const cleanData = (data) => {
    const keysToRemove = [
        "Alerta",
        "Categoría",
        "Contador asignado",
        "Estado del SLA",
        "Está escalada",
        "Gestor del proceso",
        "Hora de solicitud",
        "ID principal",
        "Máximo nivel de soporte",
        "Nivel de escalamiento",
        "Nivel de soporte actual",
        "Reabrir contador",
        "Tiempo de Resolucion",
        "Tiempo de Respuesta",
    ];
    const keysToChange = [
        {from : "#", to: "code"},
        {from : "Título", to: "Title"},
        {from : "Estado", to: "State"},
        {from : "Usuario de solicitud", to: "User"},
        {from : "Tipo de registro de servicio", to: "Type"},
        {from : "Subcategoría", to: "Subcategory"},
        {from : "Prioridad", to: "Priority"},
    ]
    data.forEach(el => {
        keysToRemove.forEach(key => {
            if (el.hasOwnProperty(key)){
                delete el[key];
            }
        })
        keysToChange.forEach(key2 => {
            if (el.hasOwnProperty(key2.from)){
                Object.assign(el, {[key2.to]: el[key2.from]});
                delete el[key2.from];
            }
        })
    });
    return data;
  }

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onload = async (event) => {
//       const data = new Uint8Array(event.target.result);
//       const workbook = read(data, {type: 'array'});
//       const firstSheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[firstSheetName];
//       const jsonData = utils.sheet_to_json(worksheet);
//       console.log({jsonData});
//       let cleanedData = await cleanData(jsonData);
//       setJson(cleanedData);
//     };
//     reader.readAsArrayBuffer(file);
//   };

  const handleRightClick = (e, data, isGroup) => {
    e.preventDefault();

    setContextMenuItem(data);
    setContextMenuItemIsInGroup(isGroup);

    let contextMenu = document.getElementById("contextMenu");
    contextMenu.style.opacity = 1;
    contextMenu.style.zIndex = 99;
    contextMenu.style.left = `${e.clientX - 50}px`;
    contextMenu.style.top = `${e.clientY}px`;
  }

  const hideContextMenu = () => {
    let contextMenu = document.getElementById("contextMenu");
    contextMenu.style.opacity = 0;
    contextMenu.style.zIndex = -1;
    contextMenu.style.left = `${0}px`;
    contextMenu.style.top = `${0}px`;
    setContextMenuItemIsInGroup(false);
    if (contectMenuGroupsOpen){
      setContextMenuGroupsOpen(false);
      setContextMenuItem(null);
    }
  }

  const handleCommentClick = () => {
    let clone = {...itemComments};
    let comment = "";
    if (clone[contectMenuItem['code']]){
      comment = prompt("Write your comment:", clone[contectMenuItem['code']]);
    }else{
      comment = prompt("Write your comment:", );
    }
    if (clone[contectMenuItem['code']]){
      if (comment === ""){
        delete clone[contectMenuItem['code']];
      }else{
        Object.assign(clone, {[contectMenuItem['code']]: comment});
      }
    }else{
      Object.assign(clone, {[contectMenuItem['code']]: comment});
    }
    setItemComments(clone);
    // localStorage.setItem('ticket-id-comments', JSON.stringify(clone));
    setShouldUpdate(true);
  }

  const handleMarkClick = () => {
    let clone = {...markedItems};
    if (markedItems[contectMenuItem['code']]){
      delete clone[contectMenuItem['code']];
      setMarkedItems(clone);
    }else{
      Object.assign(clone, {[contectMenuItem['code']]: true});
      setMarkedItems(clone);
    }
    //localStorage.setItem('ticket-marked-ids', JSON.stringify(clone));
    setShouldUpdate(true);
  }

  const handleShowGroups = () => {
    //show goups list
    setContextMenuGroupsOpen(true)
  }

  const removeFromGroup = () => {
    debugger;
    // clone groups
    let ticketCode = contectMenuItem['code'];
    let groupsClone = [...groups];
    console.log({contectMenuItem})
    // remove item from other group if exists
    for (var i = 0; i < groupsClone.length; i++){
      let gr = groupsClone[i];
      let ids = gr.ids.substring(0,gr.ids.length-1).split(",");
      let indx = ids.indexOf(`${ticketCode}`);
      if (indx !== -1){
        ids.splice(indx,1);
      }
      gr.ids = ids.toString() + ",";
    }
    // save changes to cloud
    setGroups(groupsClone);
    setShouldUpdate(true);
  }

  const handleAddToGroup = (groupId) => {
    // clone groups
    let ticketCode = contectMenuItem['code'];
    let groupsClone = [...groups];
    console.log({contectMenuItem})
    // remove item from other group if exists
    for (var i = 0; i < groupsClone.length; i++){
      let gr = groupsClone[i];
      let ids = gr.ids.substring(0,gr.ids.length-1).split(",");
      let indx = ids.indexOf(`${ticketCode}`);
      if (indx !== -1){
        ids.splice(indx,1);
      }
      gr.ids = ids.toString() + ",";
    }
    // add item to selected group
    for (var i = 0; i < groupsClone.length; i++){
      let gr = groupsClone[i];
      if (gr.id === groupId){
        gr.ids = gr.ids + `${ticketCode},`;
      }
    }
    // save changes to cloud
    setGroups(groupsClone);
    setShouldUpdate(true);
  }

  const deleteGoup = (groupId) => {
    let clone = [...groups];
    let indx = -1;

    for (var i = 0; i < clone.length; i++){
      let gr = clone[i];
      if (gr.id === groupId){
        indx = i;
      }
    }

    clone.splice(indx,1);
    setGroups(clone);
    setShouldUpdate(true);
  }

  const isHighlighted = (data, comment) => {
    if (filter === "") return true;
    let val = false;
    let keys = Object.keys(data);
    for (var i = 0; i < keys.length; i++){
      if (typeof data[keys[i]] === "string" && data[keys[i]].toLowerCase().indexOf(filter.toLowerCase()) !== -1){
        val = true;
      }
    }
    if (comment.toLowerCase().indexOf(filter.toLowerCase()) !== -1){
      val = true;
    }
    return val;
  }

  const formatData = (key, value) => {
    let formatedValue = value;
    let cellStyle={fontFamily: "monospace", fontSize: 16};

    switch(key){
        case "code":
            break;
        case "Title":
            break;
        case "State": 
            break;
        case "User":
            break;
        case "Subcategory":
            break;
        case "Priority":
            break;
        case "Type":
            formatedValue = value[0];
            break;
        default:
            break;
    }
    return <div style={cellStyle} className={`cell ${key}`} key={`${key + "-" + value}`}>{formatedValue}</div>
  }

  const countValidGroupItems = (group) => {
    // let InitialCount = (group.ids.match(new RegExp(",", "g")) || []).length;
    let ids = group.ids.substring(0,group.ids.length-1).split(",");
    let activeTickets = 0;
    for (var i = 0; i < ids.length; i++){
      let id = ids[i];
      let ticket = json.filter((item) => item.code === parseInt(id))
      if (ticket.length === 1){
        activeTickets++;
      }
    }
    return activeTickets;
  }

  const drawGroups = () => {
    return groups.map(group => {
      let countOfItems = countValidGroupItems(group);
      return <div className="group" key={`${group.id}`}>
        <button style={{backgroundColor: `${group.color ? group.color : "#2c2c2c"}`}} className="groupButton" data-toggle="collapse" data-target={`#Collapse${group.id}`} aria-expanded="true" aria-controls={`Collapse${group.id}`}>
          {group.name} {`( ${countOfItems} )`}
          <div onClick={() => deleteGoup(group.id)} className="removeGroupCloseButton">X</div>
        </button>
    
        <div id={`Collapse${group.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
          <div className="">
            {drawData({ids: group.ids}, {isGroup: true}, group)}
          </div>
        </div>
      </div>
    })
  }

  const drawData = (filter, options, grupoTmp) => {
    return json.map((row, index) => {

        if (filter){
            if (filter.not){
                let indx = filter.not.indexOf(row['State']);
                if (indx !== -1){
                    return;
                }
            }
            if (filter.is){
                let indx = filter.is.indexOf(row['State']);
                if (indx === -1){
                    return;
                }
            }
            if (filter.ids || filter.ids === ""){
              if (grupoTmp && grupoTmp.name === "Emplea3"){
                debugger;
              }
              let indx = filter.ids.indexOf(row['code']);
              if (indx === -1){
                return;
              }
            }else{
              let existsInGroup = false;
              for (var i = 0; i < groups.length; i++){
                let gItem = groups[i];
                let indx = gItem.ids.indexOf(row['code']);
                if (indx !== -1){
                  existsInGroup = true;
                }
              }
              if (existsInGroup){
                return;
              }
            }

        }

        let stateClass = row['State'];
        stateClass = stateClass.replaceAll(' ', '_')

        let cellStyle={fontFamily: "monospace", fontSize: 16};
        let commentValue = itemComments[row['code']] ? itemComments[row['code']] : "";

        let isVisible = isHighlighted(row, commentValue);


        if (isVisible){
          return (
              <div 
                className={`myrow ${stateClass} ${markedItems[row['code']] ? "marked" : ""}`} 
                key={index} 
                onClick={() => handleCopy(row)} 
                onDoubleClick={() => handleDoubleClick(row)}
                onContextMenu={(e) => handleRightClick(e, row, options?.isGroup ? options.isGroup : false)}
              >
                  {Object.keys(row).map((key, index) => 
                      formatData(key, row[key])
                  )}
                  <div style={cellStyle} className={`cell comment`}>{commentValue}</div>
              </div>
          )
        }else{
          return null;
        }
    });
  }

  const createNewGroup = (name, color) => {
    debugger;
    let clone = [...groups];
    let lastId = clone.length > 1 ? clone[clone.length-1].id : 1;
    let newId = lastId+1;
    clone.push({
      id: newId,
      color: color,
      ids: "",
      name
    });
    setGroups(clone);
    setNewGroupFormOpen(false);
    setShouldUpdate(true);
  }

  return (
    <div onClick={() => {
      hideContextMenu();
      // closeSearchBar();
    }}>
      <div className="ticket2HeaderBar">
        <div className='modeDiv'>
          <span className='modeText'>Ver:</span>
          <div className='modeBtn'>
            <div className='modeOption'>Todo</div>
            <div className='modeOption'>Filtrado</div>
          </div>
        </div>
        <div className='sincDiv'>
          <div className="loggedInUserSysAid" style={{backgroundColor: loggedInToSysAid ? 'green' : 'red'}} onClick={loginSysAid} >Logged in</div>
          {loggedInToSysAid ? <div className='sincBtn'>Sincronizar</div> : null }
        </div>
      </div>
      <div className={`sysAidLoginModal ${loggedInToSysAid ? '' : 'open'}`}>
        <div>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Usuario'></input>
          <br/>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Contraseña'></input>
          <br/>
          <div className='sysaidLoginBtn' onClick={loginSysAid}>
            Login
          </div>
        </div>
      </div>
      {newGroupFormOpen ? <NewGroupForm closeBtn={() => setNewGroupFormOpen(false) } createGroup={createNewGroup}/> : null}
      <div id="contextMenuGroups" className={`groupMenu ${contectMenuGroupsOpen ? "visible" : ""}`}>
        <h5 className="contectGroupsTitle">Seleccionar grupo</h5>
          {groups.map(g => <div className="contectGroupsGroup" key={`${g.id}`} onClick={() => handleAddToGroup(g.id)}>{g.name}</div>)}
        </div>
      <div id="contextMenu">
        <div onClick={handleCommentClick} className="contextMenuItem">Comment</div>
        <div onClick={handleMarkClick} className="contextMenuItem">Mark</div>
        <div onClick={contectMenuItemIsInGroup ? removeFromGroup : handleShowGroups} className="contextMenuItem"> {`${contectMenuItemIsInGroup ? "Remove from Group" : "Add To Group"}`}</div>
      </div>
      <div id="searchBar" className={`${openSearchBar ? 'show' : 'hide'}`}>
        <input id="seachBarInput" placeholder="Type something..." onChange={(e) => setFilter(e.target.value)} defaultValue={filter}></input>
      </div>
      {/* <input type="file" onChange={handleFileChange} /> */}
      {json.length > 0 && (
        <div className='mytable'>
          <div className="myheaders">
            <div className="myrow">
              {Object.keys(json[0]).map((key) => (
                <div className={`cell header ${key}`} key={key}>{key}</div>
              ))}
                <div className={`cell header comment`}>{"Comment"}</div>
            </div>
          </div>
          <div className="mybody">
            <h5>Asignado al Técnico</h5>
            {drawData({is: ["Asignado al Técnico"]})}
            <h5 className="groupsTitleH5">
              Grupos 
              <div className='newGroupButton' onClick={() => setNewGroupFormOpen(true)}>+</div>
            </h5>
            <div id="accordion" className='pb-3'>
              {drawGroups()}
            </div>
            <h5>Otros</h5>
            {drawData({not: ["Cierre Técnico","Asignado al Técnico","Merge Closed", "Cerrado"]})}
            {/* <h5>Cerrado</h5>
            {drawData({is: ["Cierre Técnico"]})} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SysAidApiIntegration;
