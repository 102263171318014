import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import './styles/tenerifeSales.css';
import itropLogo from './assets/itrop_logo.png';

const XlsxParserTenerifeSales = () => {
  const [data, setData] = useState([]);


  useEffect(() => {

    const handleFileDrop = (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files && files[0]) {
        processExcelFile(files[0]);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    document.addEventListener('drop', handleFileDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleFileDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  const processExcelFile = (file) => {
    const reader = new FileReader();
    console.log(1);
    reader.onload = (e) => {
      console.log(2);
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      console.log(3, jsonData);
      // Process and group data
      const groupedData = groupDataByEmail(jsonData);
      // TODO: Render the data for printing or handle it as needed
      console.log(6, groupedData);
      setData(groupedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const groupDataByEmail = (data) => {
    console.log(4, data);
    return data.reduce((acc, item) => {
      console.log(5, item);
      const email = item.EMAIL;
      if (!acc[email]) {
        acc[email] = [];
      }
      acc[email].push(item);
      return acc;
    }, {});
  };

  console.log(7, data);
  return (
    <div>
      {/* Display the processed data here */}
      {data && Object.keys(data).length > 1 ?
        <div id="printable">
          {Object.keys(data).map(item => {
            return <div key={item} className='TS-item'>
              <img className='TS-logo' src={itropLogo} />
              <h2>Nombre: {data[item][0]["NOMBRE ENVIO"].toUpperCase()}</h2>
              <h3>Email: {data[item][0]["EMAIL"]}</h3>
              {data[item].map(pedido => {
                return <div className='TS-pedido'>
                  <p>Pedido: {pedido["NUMERO_PEDIDO"]}</p>
                  <p>Pdto: {pedido["CODIGO_ALFA"]} - {pedido["Talla"]} X {pedido["CANTIDAD"]}</p>                  
                </div>
              })}
              <div class="pagebreak"> </div>
            </div>
          })}
        </div>
      : 
        <h1>Soltar Excel Pedidos Aquí</h1>
      }
    </div>
  );
}

export default XlsxParserTenerifeSales;
