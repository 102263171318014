import icon from './assets/icon.png';
import './App.css';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import sha256 from 'sha256';
import { Outlet, Link, useRoutes, useParams } from "react-router-dom";


import XlsxParser from './xlsxParser2';
import XlsxParserCEX from './xlsxParserCEX';
import ThemeShowroom from './ThemeShowroom';
import SysAidApiIntegration from './SysAidApiIntegration';
import XlsxParserTenerifeSales from './xlsxParserTenerifeSales';

const Home = () => {
  return <div style={{height: '100%'}}>
    {/* <ul id="mainNavList">
      <li>
        <Link className='mainNavListItem' to="tickets">Tickets</Link>
      </li>
      <li>
        <Link className='mainNavListItem' to="fac-cex"> CEX</Link>
      </li>
      <li>
        <Link className='mainNavListItem' to="TenerifeSales">Ventas Tenerife</Link>
      </li>
    </ul> */}
  </div>
}

const Layout = ({handleLogout}) => {
  return <div>
    <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: 25, backgroundColor: "Black", zIndex: 99}}>
      <nav>
        <ul id="navList">
          <li>
            <Link to="tickets">Tickets</Link>
          </li>
          {/* <li>
            <Link to="tickets2">Tickets 2.0</Link>
          </li> */}
          <li>
            <Link to="fac-cex">CEX</Link>
          </li>
          <li>
            <Link to="TenerifeSales">C.D.-Tenerife</Link>
          </li>
        </ul>
        <div className='logoutDiv'>
          <div className='logoutBtn' onClick={handleLogout}>Log Out</div>
        </div>
      </nav>
    </div>
    <div style={{paddingTop: 25}}><Outlet/></div>
  </div>;
}

const NoMatch = () => {
  return <div><p>No match in page</p></div>;
}

function App() {

  const [loggedIn, setLoggedIn] = useState({is: false});
  const [password, setPassword] = useState("");
  const [logoutReady, setLogoutReady] = useState(false);

  const handleLogout = () => {
    localStorage.setItem("tvcologan_ticket_tool", null)
    setLoggedIn({is: false, passphrase: ""});
    setLogoutReady(false);
    setPassword("");
  }

  let routes = [
    {
      path: "/",
      element: <Layout handleLogout={handleLogout}/>,
      children: [
        { index: true, element: <Home /> },
        {
          path: "/tickets",
          element: <XlsxParser passphrase={loggedIn.passphrase} />
        },
        {
          path: "/tickets2",
          element: <SysAidApiIntegration passphrase={loggedIn.passphrase} />
        },
        {
          path: "/fac-cex",
          element: <XlsxParserCEX/>
        },
        {
          path: "/TenerifeSales",
          element: <XlsxParserTenerifeSales/>
        },
        { path: "*", element: <NoMatch /> },
      ],
    },
  ];
  let element = useRoutes(routes);

  useEffect(() => {
    let storageKey = localStorage.getItem("tvcologan_ticket_tool");
    if (storageKey){
      setLoggedIn({is: true, passphrase: storageKey});
    }
  },[])

  useEffect(() => {
    if (loggedIn.is){
      setTimeout(() => {
        setLogoutReady(true);
      }, 1000)
    }
  },[loggedIn])

  const handleLogin = () => {

    if (password !== ""){
      // axios({
      //   method: "POST",
      //   url: `https://gruponumero1.sysaidit.com/api/v1/login`,
      //   data: {
      //     user_name: username,
      //     password: password
      //   }
      // }).then(resp => {
      //   console.log({resp});
      // })

      let passphrase = sha256.x2(password);
      localStorage.setItem("tvcologan_ticket_tool", passphrase)
      setLoggedIn({is: true, passphrase});
      setPassword("");
    }
  }

  const handleKeyPressInput = (e) => {
    if (e.nativeEvent.key === "Enter"){
      handleLogin();
    }
  }

  return (
    <div className="App">
      {loggedIn.is ? 
        element
      :
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <div style={{marginTop: 50}}>
          <img src={icon} style={{maxWidth: 50}}></img>
        </div>
        <div>
          <h1>Login</h1>
        </div>
        <div style={{marginBottom: 20, width: 200}}>
          <input 
            type="password"
            style={{padding: 5, borderRadius: 5, width: '90%'}}
            defaultValue={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="password"
            onKeyDown={handleKeyPressInput}
          ></input>
        </div>
        <div style={{width: 200}}>
          <button style={{padding: 5, width: '100%'}} onClick={handleLogin}>Login</button>
        </div>
      </div>
      
      }
    </div>
  );
}

export default App;
